<template>
  <div class="tracesource-file">
    <div class="tracesource-file-header">
      <div class="tracesource-file-header-left">
        <span @click="sureBack"> <i class="back"></i>历史溯源 </span>
        <div class="progress">
          <el-progress
            :text-inside="true"
            :stroke-width="16"
            :percentage="progress"
          ></el-progress>
        </div>
      </div>
      <div class="tracesource-file-header-title">
        文档/图片/视频/音频待溯源列表
      </div>
      <div class="tracesource-file-header-right">
        <span class="refresh" @click="refresh">
          <img :src="require('assets/images/refresh-gray.png')" alt="" />
        </span>
        <span class="charactar"
          >总字符：<span class="count">{{ totalCharacter }}</span></span
        >
      </div>
    </div>
    <div class="tracesource-file-reportform">
      <div class="tracesource-file-reportform-table">
        <el-table :data="reportPageList" style="width: 100%">
          <el-table-column prop="" label="序号" width="90">
            <template slot-scope="{ row: { status }, $index: index }">
              <div
                :class="[
                  'states',
                  status === 0
                    ? 'orange'
                    : status === 1
                    ? 'blue'
                    : status === 2
                    ? 'green'
                    : 'gray',
                ]"
              >
                {{
                  status === 0
                    ? "未溯源"
                    : status === 1
                    ? "溯源中"
                    : status === 2
                    ? "完成"
                    : "失败"
                }}
              </div>
              <div>{{ index + 1 }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="fileName" label="文件名"></el-table-column>
          <el-table-column label="数据大小" width="80">
            <template slot-scope="scope">{{ scope.row.fs }}</template>
          </el-table-column>
          <el-table-column label="撤销" width="60" align="center">
            <template slot-scope="{ row: { id, status } }">
              <span class="revoker" @click="revokeSelectTask(id, status)"
                ><img :src="require('assets/images/revoke.png')" alt=""
              /></span>
            </template>
          </el-table-column>
          <el-table-column label="状态" width="60" align="center">
            <template slot-scope="scope">
              <span class="status">
                <img
                  v-if="scope.row.status == 1"
                  :src="require('assets/images/error.png')"
                  alt=""
                />
                <img
                  v-else
                  :src="require('assets/images/success.png')"
                  alt=""
                />
              </span>
            </template>
          </el-table-column>
          <el-table-column label="溯源评价" width="150">
            <template slot-scope="{ row: { reportType, level, evaluation } }">
              <div class="evaluate-wrap">
                <div class="nothing" v-if="!evaluation">未溯源</div>
                <div v-if="reportType == 1 && evaluation" class="original">
                  <img :src="getOriginalIcon(level)" alt="" />
                  <span class="txt">严格原创率{{ evaluation }}%</span>
                </div>
                <div v-if="reportType > 1 && evaluation" class="homology">
                  <img
                    v-show="evaluation == '有同源'"
                    :src="require('assets/images/homology-y.png')"
                    alt=""
                  />
                  <img
                    v-show="evaluation == '无同源'"
                    :src="require('assets/images/homology-n.png')"
                    alt=""
                  />
                  <span
                    :class="[
                      'txt',
                      evaluation == '有同源'
                        ? 'is-homology'
                        : evaluation == '无同源'
                        ? 'no-homology'
                        : '',
                    ]"
                  >
                    {{ evaluation }}
                  </span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="在线授权" width="110">
            <template slot-scope="scope">
              <span
                :class="[
                  'authorize',
                  scope.row.originalIds ? 'is-blue' : 'is-gray',
                ]"
                @click="showAuthorize(scope.row.originalIds, scope.row.id)"
              >
                {{ scope.row.originalIds ? "可在线授权" : "无在线授权" }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="溯源报告" width="100">
            <template slot-scope="{ row, row: { reportUrl: pdf } }">
              <div class="operate">
                <span class="view" @click="openPdf(row)"><i></i></span>
                <span class="share" @click="QrCodeShow(pdf)"><i></i></span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="tracesource-file-footer">
      <Pager
        :hide-on-single-page="true"
        :pageNum="pageNum"
        :pageSize="pageCount"
        @current-change="currentChange"
      />
    </div>
    <!--返回撤销提示-->
    <el-dialog
      title="提示"
      :visible.sync="showTips"
      :modal="false"
      :append-to-body="true"
      :close-on-click-modal="false"
      width="340px"
    >
      <div class="content">返回到编辑区，当前未溯源文件将被撤销</div>
      <template #footer>
        <div class="footer">
          <span @click="sureBack">确定返回</span>
          <span @click="showTips = false">取消</span>
        </div>
      </template>
    </el-dialog>
    <!-- 提示弹窗 -->
    <el-dialog
      title="提示"
      :modal="false"
      :close-on-click-modal="false"
      :visible.sync="dialogTips"
      class="tips"
    >
      <div class="content" v-if="curCharacter >= totalCharacter">
        <div class="content-text">
          本次溯源将扣减<span>{{ totalCharacter }}</span
          >个字符，字符包现有<span>{{ curCharacter }}</span
          >个字符，该字符将支付给重复字段的原创作者。
        </div>
      </div>
      <div
        class="content"
        v-else
        :class="curCharacter < totalCharacter ? 'active' : ''"
      >
        <i class="content-icon"></i>
        <div class="content-text">
          本次溯源将扣减<span>{{ totalCharacter }}</span
          >个字符，字符包现有<span>{{ curCharacter }}</span
          >个字符，<span>已不足。</span>
        </div>
      </div>
      <div class="footer">
        <div class="footer-btn" @click="sureSubmit">
          {{ curCharacter >= totalCharacter ? "确定" : "取消" }}
        </div>
        <div
          class="footer-btn"
          v-if="curCharacter >= totalCharacter"
          @click="handleQuickPay"
        >
          字符包快捷支付
        </div>
        <div class="footer-btn" v-else @click="buyCharPack">购买字符包</div>
      </div>
    </el-dialog>
    <!-- 链接二维码弹窗 -->
    <el-dialog
      title="二维码"
      :close-on-click-modal="false"
      :visible.sync="QrCodeVisible"
      @close="handleCloseQrcode"
      class="qrcode"
    >
      <div class="content">
        <div class="notes">分享二维码</div>
        <div class="imgBox">
          <div ref="qrcode_box"></div>
        </div>
        <!-- <el-button type="primary" @click="submitOrder">提交订单</el-button> -->
      </div>
    </el-dialog>
    <TraceSourceAuthorizationList
      ref="traceAuthorizeList"
      :show-authorize-list="showAuthorizeList"
      @showAuthorizeDetail="showAuthorizeInfo"
      @close="showAuthorizeList = false"
    />
    <TraceSourceAuthorizationInfo ref="traceAuthorize" />
  </div>
</template>

<script>
import {
  checkInterestsNumber,
  batchTraceSource,
  findTraceReportByPage,
  findTraceParseQueueTotalCharacter,
  delTraceSourceTask,
  postTraceSetup,
} from "api/traceTheSource";
import { previewUrl } from "api/common";
import QrCode from "qrcodejs2";
import TraceSourceAuthorizationList from "components/traceSource/TraceSourceAuthorizationList";
import TraceSourceAuthorizationInfo from "components/traceSource/TraceSourceAuthorizationInfo";
import Pager from "components/idiom/pager/IdiomPager";
export default {
  name: "TraceSourceFile",
  data() {
    return {
      //报表数据
      reportPageList: [],
      //原创等级
      originalLang: [
        {
          level: "A+",
          icon: require("assets/images/original-a-plus.png"),
        },
        {
          level: "A",
          icon: require("assets/images/original-a.png"),
        },
        {
          level: "B+",
          icon: require("assets/images/original-b-plus.png"),
        },
        {
          level: "B",
          icon: require("assets/images/original-b.png"),
        },
        {
          level: "C",
          icon: require("assets/images/original-c.png"),
        },
      ],
      //当前页
      pageNum: 1,
      //一页大小
      pageSize: 6,
      //总页数
      pageCount: 1,
      //总数量
      pageTotal: 0,
      //显示字符数量
      curCharacter: 0,
      //总扣减字符
      totalCharacter: 0,
      //显示可在线授权列表
      showAuthorizeList: false,
      //显示扣减字符弹窗
      dialogTips: false,
      //返回撤销提示
      showTips: false,
      //计时器id
      timer: null,
      //默认服务器路径
      basePath: "http://fafs.antiplagiarize.com/",
      fullPreviewPdf: "",
      // 分享二维码弹窗
      QrCodeVisible: false,
      qr: "",
      progress: 0,
    };
  },
  methods: {
    //返回
    goBack() {
      this.showTips = true;
    },
    //确定返回
    sureBack() {
      this.$router.go(-1);
      setTimeout(() => {
        this.$bus.$emit("firstTraced");
      });
      // delTraceSourceTask().then((res) => {
      //   if (res.code == 200) {
      //     clearInterval(this.timer);
      //     this.$message({
      //       type: "success",
      //       message: "撤销成功",
      //       duration: 800,
      //     });
      //     this.showTips = false;
      //     this.$router.go(-1);
      //   }
      // });
    },
    //刷新
    refresh() {
      this.findTraceReportQueue();
    },
    getOriginalIcon(l) {
      if (l) {
        return this.originalLang.filter((i) => i.level === l)[0].icon;
      }
    },
    //撤销选中任务
    revokeSelectTask(reportId, status) {
      if (status === 2) return;
      const params = { reportId };
      //撤销
      delTraceSourceTask(params).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "撤销成功",
            duration: 800,
          });
        }
      });
    },
    //当前页改变
    currentChange(cur) {
      this.pageNum = cur;
      this.$store.commit("playLifeLoading", true);
      this.findTraceReportQueue();
    },
    //打开报表pdf
    async openPdf(row) {
      if (row.reportUrl) {
        try {
          // eslint-disable-next-line
          OpenDocument2(
            row.fileName + "-溯源报告.pdf",
            this.$store.state.onlineBasePath + row.reportUrl
          );
        } catch (e) {
          console.log(e);
        }
      } else {
        this.$message({
          type: "success",
          message: "暂无报告",
          duration: 800,
        });
      }
    },
    //获取完整编码的预览地址
    async previewPdf(source) {
      const params = {
        url: this.basePath + source,
      };
      const res = await previewUrl(params);
      if (res.code == 200) {
        this.fullPreviewPdf = res.data;
      }
    },
    //查询批量溯源扣减总字符
    checkTotalCharacter() {
      findTraceParseQueueTotalCharacter().then((res) => {
        if (res.code == 200) {
          this.totalCharacter = res.data ? res.data : 0;
          this.getFontNumber();
        }
      });
    },
    /***
     * @param { Function } afterFun 检查字符成功之后的回调
     */
    // 获取字符数量
    async getFontNumber(afterFun) {
      const data = {
        deductNum: this.totalCharacter,
        type: 2,
      };
      const res = await checkInterestsNumber(data);
      if (res.code === 200 || res.code === 7002) {
        this.curCharacter = res.data;
        if (this.$route.params.payType == 1) {
          if (this.curCharacter < this.totalCharacter) {
            this.dialogTips = true;
          } else {
            this.getReportData();
          }
        } else {
          this.dialogTips = true;
        }
        afterFun?.();
      } else {
        this.$message({
          type: "error",
          message: res.message,
          duration: 800,
        });
      }
    },
    //确定
    sureSubmit() {
      if (this.curCharacter >= this.totalCharacter) {
        this.dialogTips = false;
        return this.getReportData();
      }
      this.dialogTips = false;
    },
    sourceProgress() {
      let dis = 0;
      let timer1, timer2, timer3;
      this.progress = dis;
      setTimeout(() => {
        timer1 = setInterval(() => {
          if (this.progress == 100) {
            return clearInterval(timer1);
          }
          if (dis >= 50) {
            clearInterval(timer1);
            timer2 = setInterval(() => {
              if (this.progress == 100) {
                return clearInterval(timer2);
              }
              dis += Math.floor(Math.random() * 3 + 1);
              this.progress = dis;
              if (dis >= 80) {
                clearInterval(timer2);
              }
            }, 200);
          }
          dis += 3;
          this.progress = dis;
        }, 100);
        // timer3 = setInterval(() => {
        //   if (this.progress == 100) {
        //     clearInterval(timer1);
        //     clearInterval(timer2);
        //     clearInterval(timer3);
        //   }
        // }, 50);
      }, 500);
    },
    //开始批量溯源
    async getReportData() {
      const res = await batchTraceSource();
      if (res.code == 200) {
        this.sourceProgress();
        this.$message({
          type: "success",
          message: "开始批量溯源",
          duration: 800,
        });
        const last = Date.now();
        this.timer = setInterval(() => {
          const now = Date.now();
          if (now - last > 1000 * 60 * 5) {
            clearInterval(this.timer);
          }
          this.findTraceReportQueue();
        }, 1500);
      }
    },
    //查询批量溯源队列
    async findTraceReportQueue() {
      const params = {
        page: this.pageNum,
        limit: this.pageSize,
      };
      findTraceReportByPage(params)
        .then((res) => {
          if (res.code == 200) {
            this.progress = +res.data.percentage ? +res.data.percentage : 0;
            this.reportPageList = res.data.queue.data;
            this.pageTotal = res.data.queue.total;
            this.pageCount = Math.ceil(this.pageTotal / this.pageSize);
            this.totalCharacter = res.data.totalPrice;
            if (res.data.percentage == 100 || res.data.percentage > 100) {
              clearInterval(this.timer);
              this.progress = 100;
            }
          }
        })
        .catch((e) => {
          this.$message({
            type: "error",
            message: e.name + e.message,
            duration: 800,
          });
        });
    },
    // 生成二维码
    crateQrcode(pdf) {
      this.qr = new QrCode(this.$refs.qrcode_box, {
        text: this.basePath + pdf,
        width: 128,
        height: 128,
        colorDark: "#000000",
        colorLight: "#ffffff",
      });
    },
    // 打开分享二维码弹窗
    QrCodeShow(pdf) {
      this.QrCodeVisible = true;
      this.$nextTick(() => {
        this.crateQrcode(pdf);
      });
    },
    // 关闭去除二维码
    handleCloseQrcode() {
      this.$refs.qrcode_box.innerHTML = "";
    },
    /***
     * 点击在线授权
     * @param { String } ids 没有ids表示无在线授权
     * @param { Number } id 当前文件id
     */
    showAuthorize(ids, id) {
      if (!ids) return;
      this.showAuthorizeList = true;
      this.$refs["traceAuthorizeList"].findAuthorizeList(id);
    },
    //显示授权许可信息
    showAuthorizeInfo(item) {
      this.$refs["traceAuthorize"].authorization(item);
    },
    //快捷字符包支付
    handleQuickPay() {
      this.setQuickPay();
      this.sureSubmit();
    },
    //设置快捷支付
    async setQuickPay() {
      const data = {
        id: this.$route.params.setId,
        payType: 1,
      };
      await postTraceSetup(data);
    },
    buyCharPack() {
      try {
        //eslint-disable-next-line
        OpenBrowser("https://www.writemall.com/CopyrightPracticeDetail/2");
      } catch (e) {
        console.log(e);
      }

      window.isifHaveBuyDialog();
    },
  },
  components: {
    TraceSourceAuthorizationList,
    TraceSourceAuthorizationInfo,
    Pager,
  },
  created() {
    //首次查询一次上传的文件，等待1秒再核减字符
    this.findTraceReportQueue();
    setTimeout(() => {
      this.checkTotalCharacter();
    }, 2000);
  },
};
</script>

<style lang="scss" scoped>
.tracesource-file {
  @include flex-center(column);
  justify-content: normal;
  align-items: normal;
  min-width: 644px;
  min-height: 550px;
  height: 100vh;
  &-header {
    @include flex-between;
    width: 100%;
    box-sizing: border-box;
    padding: 12px;
    background: #fbfcfd;
    box-sizing: border-box;
    box-shadow: 0 0 0px 2px rgba(122, 179, 179, 0.1);
    &-left {
      @include flex-start;
      @include noselect;
      position: relative;
      font-size: 12px;
      font-weight: 500;
      color: #999999;
      cursor: pointer;
      width: 40%;
      .back {
        display: inline-block;
        position: relative;
        width: 6.3px;
        height: 11px;
        margin-right: 3px;
        margin-bottom: 1px;
        vertical-align: middle;

        &::before {
          content: "";
          position: absolute;
          vertical-align: middle;
          width: 100%;
          height: 100%;
          background: url("~assets/images/arrow-left.png") no-repeat;
          background-size: 100% 100%;
        }
      }
      .progress {
        width: 150px;
        margin-left: 10px;
      }
    }
    &-title {
      display: inline-block;
      width: 20%;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      white-space: nowrap;
    }
    &-right {
      @include noselect;
      // @include flex-start;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      // margin-right: 18px;
      display: inline-block;
      width: 40%;
      text-align: right;
      .count {
        margin-left: 5px;
        color: #4587ff;
      }
      .refresh {
        img {
          vertical-align: middle;
        }
        margin-right: 5px;
        cursor: pointer;
      }
    }
  }
  &-reportform {
    width: 100%;
    padding: 0 10px;
    margin: 0 auto;
    flex: 1;
    box-sizing: border-box;
    &-table {
      margin-top: 21px;
      ::v-deep .el-table::before {
        background-color: transparent;
      }
      ::v-deep .el-table td.el-table__cell {
        &:first-child {
          @include noselect;
        }
        border-bottom: none;
        .cell {
          @include ellipsis;
          .states {
            position: absolute;
            left: 30px;
            top: 50%;
            width: 50px;
            height: 18px;
            margin-top: -9px;
            font-size: 12px;
            font-weight: 800;
            text-align: center;
            line-height: 18px;
            color: #fff;
            z-index: 1;

            &::before {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              display: block;
              width: 100%;
              height: 100%;
              z-index: -1;
            }
          }

          .gray {
            &::before {
              background: url("~assets/images/rhombus-gray.png") no-repeat;
            }

            color: #999;
          }

          .green {
            &::before {
              background: url("~assets/images/rhombus-green.png") no-repeat;
            }
          }

          .orange {
            &::before {
              background: url("~assets/images/rhombus-orange.png") no-repeat;
            }
          }

          .blue {
            &::before {
              background: url("~assets/images/rhombus-blue.png") no-repeat;
            }
          }
          .revoker,
          .status {
            @include noselect;
            cursor: pointer;
          }
          .evaluate-wrap {
            @include noselect;
            font-size: 12px;
            font-weight: 500;
            .nothing {
              color: #999;
            }
            .original,
            .homology {
              @include flex-start;
              .txt {
                margin-left: 5px;
                color: #4587ff;
              }
            }
            .homology {
              .txt {
                &.is-homology {
                  color: #e15240;
                }
                &.no-homology {
                  color: #4587ff;
                }
              }
            }
          }
          .authorize {
            font-size: 12px;
            font-weight: 500;
            text-decoration: underline;
            cursor: pointer;
            &.is-blue {
              color: #4587ff;
            }
            &.is-gray {
              color: #999999;
            }
          }
          .operate {
            @include flex-between;
            span {
              @include flex-start;
              cursor: pointer;
              i {
                position: relative;
                width: 16px;
                height: 16px;
              }
            }
            .view i,
            .share i {
              &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                background-size: 100% 100%;
              }
            }
            .view i::before {
              background: url("~assets/images/report-view-gray.png") no-repeat;
            }
            .view:hover i::before {
              background: url("~assets/images/report-view-blue.png") no-repeat;
            }
            .share i::before {
              background: url("~assets/images/report-share-gray.png") no-repeat;
            }
            .share:hover i::before {
              background: url("~assets/images/report-share-blue.png") no-repeat;
            }
          }
        }
      }
      ::v-deep .el-table th.el-table__cell.is-leaf {
        border-bottom: none;
      }
    }
  }
  &-footer {
    @include noselect;
    margin: 21px auto;
  }
  //字符提示
  .tips {
    ::v-deep .el-dialog {
      display: flex;
      flex-direction: column;
      margin-top: 20vh !important;
      width: 340px;
      background: #ffffff;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      .el-dialog__header {
        padding: 12px 12px 0px;
        .el-dialog__title {
          font-size: $font-size-small;
          font-family: PingFang SC;
          font-weight: 400;
          color: $color-text-gray;
        }
        .el-dialog__headerbtn {
          top: 12px;
          right: 12px;
        }
      }
      .el-dialog__body {
        padding: 15px 39px;
        flex: 1;
        .content {
          font-size: $font-size-medium-x;
          font-family: PingFang SC;
          font-weight: 500;
          color: $color-text-black;
          line-height: 26px;
          display: flex;
          align-items: normal;
          span {
            color: #eb441e;
          }
          .content-icon {
            width: 18px;
            height: 18px;
            flex-shrink: 0;
            @include backgroundGroup("~assets/images/del-error.png");
            transform: translateY(4px);
            margin-right: 9px;
          }
        }
        .active {
          margin-top: 20px;
        }
        .footer {
          display: flex;
          justify-content: space-between;
          margin-top: 33px;
          .footer-btn {
            font-size: $font-size-medium;
            font-family: PingFang SC;
            font-weight: 500;
            color: $color-bg-heightlight;
            cursor: pointer;
          }
        }
      }
    }
  }
  //分享二维码
  .qrcode {
    ::v-deep .el-dialog {
      // top: 50%;
      // transform: translateY(-50%);
      margin-top: 20vh !important;
      width: 500px;
      height: 390px;
      background: #ffffff;
      border: 0px solid #818790;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      .el-dialog__header {
        height: 42px;
        background: #f4f6f7;
        padding: 0;
        line-height: 42px;
        .el-dialog__title {
          margin-left: 15px;
          font-size: $font-size-medium;
          font-family: PingFang SC;
          font-weight: 500;
          color: #393939;
        }
        .el-dialog__headerbtn {
          top: $font-size-small;
          right: $font-size-small;
          font-size: $font-size-large;
        }
      }
      .el-dialog__body {
        padding: 65px 20px;
        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .notes {
            font-size: $font-size-medium;
            font-family: PingFang SC;
            font-weight: 500;
            color: $color-text-black;
          }
          .imgBox {
            margin-top: 30px;
          }
        }
      }
    }
  }
}

::v-deep .el-dialog {
  margin-top: 20vh !important;
  background: #ffffff;
  box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
  .el-dialog__header {
    padding: 12px 12px 0px;
    .el-dialog__title {
      font-size: $font-size-small;
      font-family: PingFang SC;
      font-weight: 400;
      color: $color-text-gray;
    }
    .el-dialog__headerbtn {
      top: 12px;
      right: 12px;
    }
  }
  .el-dialog__body {
    padding: 40px 25px;
    .content {
      @include flex-center;
      font-size: $font-size-medium-x;
      font-weight: 500;
      color: $color-text-black;
      line-height: 26px;
    }
  }
  .el-dialog__footer {
    padding: 10px 69px 20px 67px;
    .footer {
      @include flex-between;
      span {
        font-size: $font-size-medium;
        font-weight: 500;
        color: $color-text-active;
        cursor: pointer;
      }
    }
  }
}
</style>
